<template>
  <v-content>
    <slot />

    <dashboard-core-footer />
  </v-content>
</template>

<script>
export default {
  name: 'DashboardCoreView',

  components: {
    DashboardCoreFooter: () => import('@/components/core/footer'),
  },
};
</script>
